import { useEffect, useState } from 'react';
import { toJS } from 'mobx';
import styled, { DefaultTheme } from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { getYMD } from 'helpers/DateTimeUtils';
import { useHistory } from 'react-router-dom';

import { devices } from 'constants/mediaConstants';
import { Namespaces } from 'languages';

import { HeaderStore } from 'store/HeaderStore';
import { LoginStore } from 'store/LoginStore';

import { Loader } from 'components/Loader/Loader';
import { EmptyResult } from 'components/EmptyResult/EmptyResult';

import { Flex } from 'styled/Flex';
import Layout from 'styled/Layout';

import { SubscriptionItem } from 'pages/SubscriptionsList/SubscriptionItem';
import { SubscriptionsStore, SubscriptionsListType } from 'store/SubscriptionStore';

import CloseIcon from 'assets/icons/close.svg';
import AlertImage from 'assets/icons/alert.svg';
import {
  SUBSCRIPTION_ACTIVE_STATUS,
  SUBSCRIPTION_PAST_DUE_STATUS,
  SUBSCRIPTION_UNDER_NOTICE_STATUS,
  SUBSCRIPTION_CANCELLED_STATUS,
} from 'constants/subscriptionConstants';
import { SubscriptionPaymentsList } from './SubscriptionPaymentsList';
import { RootPageTitle } from 'components/RootPageTitle';

const SubscriptionsElement = styled(Layout)`
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
`;

type ModalBodyPropsType = {
  theme: DefaultTheme;
  isGray?: boolean;
};

const ModalBody = styled.div`
  position: relative;
  width: 545px;
  max-height: 100vh;
  overflow: auto;
  padding: 72px 38px 42px 34px;
  background: ${(props: ModalBodyPropsType) =>
    props.isGray ? props.theme.colors.lightGray : props.theme.colors.white};
  border-radius: 30px;

  @media ${devices.mobile} {
    width: 90%;
    padding: 72px 25px 30px;
  }
`;

const IconClose = styled.div`
  position: absolute;
  right: 40px;
  top: 40px;
  width: 30px;
  height: 30px;
  background-size: contain;
  background-image: url(${CloseIcon});
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  @media ${devices.mobile} {
    right: 30px;
    top: 30px;
    height: 25px;
    width: 25px;
  }
`;

const StatusHeader = styled.div`
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  margin: 0 15px 12px;
  @media ${devices.mobile} {
    font-size: 20px;
  }
`;

const LoadMoreButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  width: 130px;
  margin: 25px auto 0;
  font-size: 14px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  box-sizing: border-box;
  border-radius: 19px;
  cursor: pointer;
  user-select: none;
  color: ${({ theme }) => theme.colors.darkBlack};

  @media ${devices.mobile} {
    max-height: 38px;
    width: 130px;
    padding: 12px 30px;
    font-size: 12px;
  }
  &:hover {
    opacity: 0.7;
  }
`;

const ModalTitle = styled.div`
  margin-top: 33px;
  color: ${({ theme }) => theme.colors.darkBlack};
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;

  @media ${devices.mobile} {
    margin-top: 25px;
    font-size: 18px;
  }
`;

const ModalDescription = styled.div`
  margin-bottom: 27px;
  color: ${({ theme }) => theme.colors.middleDark};
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;

  @media ${devices.mobile} {
    font-size: 13px;
    line-height: 20px;
  }
`;

const CancelIcon = styled.div`
  width: 72px;
  height: 72px;
  margin: 0 auto;
  background-size: contain;
  background-image: url(${AlertImage});
`;

const CancelModalText = styled.div`
  margin: 25px 0 7px;
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
`;

const BoldText = styled.b`
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
`;

const CancelModalSubText = styled.div`
  color: #6f6f6f;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  border-radius: 28px;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  text-align: center;

  @media ${devices.mobile} {
    flex-direction: column;
  }
`;

const ModalCancelButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 180px;
  height: 50px;
  margin: 20px auto;
  color: #6f6f6f;
  border: 1px solid #a6a6a6;
  border-radius: 25px;
  cursor: pointer;
  user-select: none;
  :hover {
    background: rgba(0, 0, 0, 0.05);
  }
  @media ${devices.mobile} {
    margin-bottom: 10px;
  }
`;
const ModalRejectButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 50px;
  margin: 20px auto;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.darkBlack};
  border-radius: 28px;
  cursor: pointer;
  user-select: none;
  :hover {
    opacity: 0.9;
  }
  @media ${devices.mobile} {
    margin: 5px auto 10px;
  }
`;

export const SubscriptionsListPage = observer(() => {
  const {
    getSubscriptionsList,
    subscriptionsList,
    loading,
    page,
    loadMore,
    clearSubscriptionsList,
    deleteSubscriptionsListItem,
  } = SubscriptionsStore;
  const { setBackButton } = HeaderStore;
  const { isUserLoggedIn } = LoginStore;
  const history = useHistory();
  const { t } = useTranslation(Namespaces.UI);
  const [modalType, setModalType] = useState('');
  const [modalLoading, setModalLoading] = useState(false);
  const [sortedSubscriptionsListData, setSortedSubscriptionsListData] = useState<
    SubscriptionsListType[][]
  >([]);

  const [activeSubscription, setActiveSubscription] = useState<
    SubscriptionsListType | undefined
  >();

  const handleRemoveItem = () => {
    setModalType('');
    setModalLoading(false);
  };

  useEffect(() => {
    setBackButton(true);
    if (isUserLoggedIn()) {
      getSubscriptionsList();
    } else {
      localStorage.prevPath = history.location.pathname;
      history.replace('/auth');
    }
  }, [setBackButton, isUserLoggedIn, getSubscriptionsList]);

  const subscriptionsListData = toJS(subscriptionsList);
  const pageData = toJS(page);

  useEffect(() => {
    if (subscriptionsListData?.length && subscriptionsListData[0]) {
      const subscriptionsListActiveGroup: SubscriptionsListType[] = [];
      const subscriptionsListPastDueGroup: SubscriptionsListType[] = [];
      const subscriptionsListUnderNoticeGroup: SubscriptionsListType[] = [];
      const subscriptionsListCancelledGroup: SubscriptionsListType[] = [];
      const sortedSubscriptionsList: SubscriptionsListType[][] = [];
      for (let i = 0; i < subscriptionsListData.length; i++) {
        switch (subscriptionsListData[i].status) {
          case SUBSCRIPTION_ACTIVE_STATUS: {
            subscriptionsListActiveGroup.push(subscriptionsListData[i]);
            break;
          }
          case SUBSCRIPTION_PAST_DUE_STATUS: {
            subscriptionsListPastDueGroup.push(subscriptionsListData[i]);
            break;
          }
          case SUBSCRIPTION_UNDER_NOTICE_STATUS: {
            subscriptionsListUnderNoticeGroup.push(subscriptionsListData[i]);
            break;
          }
          case SUBSCRIPTION_CANCELLED_STATUS: {
            subscriptionsListCancelledGroup.push(subscriptionsListData[i]);
            break;
          }
          default:
            break;
        }
      }
      if (subscriptionsListActiveGroup.length) {
        sortedSubscriptionsList.push(subscriptionsListActiveGroup);
      }
      if (subscriptionsListPastDueGroup.length) {
        sortedSubscriptionsList.push(subscriptionsListPastDueGroup);
      }
      if (subscriptionsListUnderNoticeGroup.length) {
        sortedSubscriptionsList.push(subscriptionsListUnderNoticeGroup);
      }
      if (subscriptionsListCancelledGroup.length) {
        sortedSubscriptionsList.push(subscriptionsListCancelledGroup);
      }
      setSortedSubscriptionsListData(sortedSubscriptionsList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionsList]);

  useEffect(() => {
    return () => {
      clearSubscriptionsList();
    };
  }, [clearSubscriptionsList]);

  const handleRefreshSubscriptions = () => {
    getSubscriptionsList();
  };

  const renderStatusName = (status: string) => {
    switch (status) {
      case SUBSCRIPTION_ACTIVE_STATUS:
        return t('subscriptionGroupHeaderActive');
      case SUBSCRIPTION_PAST_DUE_STATUS:
        return t('subscriptionGroupHeaderPastDue');
      case SUBSCRIPTION_UNDER_NOTICE_STATUS:
        return t('subscriptionGroupHeaderUnderNotice');
      case SUBSCRIPTION_CANCELLED_STATUS:
        return t('subscriptionGroupHeaderCancelled');
      default:
        return null;
    }
  };

  const renderModalInfoBody = () => {
    const {
      service: { name, description },
    } = activeSubscription as SubscriptionsListType;
    return (
      <>
        <ModalTitle>{name}</ModalTitle>
        {description ? <ModalDescription>{description}</ModalDescription> : null}
      </>
    );
  };

  const renderModalCancelBody = () => {
    const { id, lastAtWithNoticePeriod } = activeSubscription as SubscriptionsListType;
    return (
      <>
        <CancelIcon />
        <CancelModalText>{t('cancelSubscription')}</CancelModalText>
        {lastAtWithNoticePeriod ? (
          <CancelModalSubText>
            {t('cancelSubscriptionMessageWithNotice1')}
            <BoldText>
              {t('cancelSubscriptionMessageWithNotice2', {
                lastAtWithNoticePeriod: `${getYMD(lastAtWithNoticePeriod)}`,
              })}
            </BoldText>
            <>.</>
          </CancelModalSubText>
        ) : (
          <CancelModalSubText>{t('cancelSubscriptionMessage')}</CancelModalSubText>
        )}

        <ButtonWrap>
          <ModalCancelButton
            onClick={() => {
              if (!modalLoading) {
                setModalLoading(true);
                deleteSubscriptionsListItem(id, handleRemoveItem);
              }
            }}
          >
            {modalLoading ? <Loader small /> : t('cancelSubscriptionYes')}
          </ModalCancelButton>
          <ModalRejectButton
            onClick={() => {
              setModalType('');
            }}
          >
            {t('cancelSubscriptionNo')}
          </ModalRejectButton>
        </ButtonWrap>
      </>
    );
  };

  const subscriptionsListCard = (subscriptionGroup: SubscriptionsListType[]) => {
    const { id, status } = subscriptionGroup[0];

    return (
      <div key={id}>
        <Flex justifyContent="space-between" alignItems="center" margin="35px 0 0">
          <StatusHeader>{renderStatusName(status)}</StatusHeader>
        </Flex>
        {subscriptionGroup.map((item: SubscriptionsListType) => {
          return (
            <SubscriptionItem
              key={item.id}
              subscriptionItem={item}
              setActiveSubscription={setActiveSubscription}
              setModalType={setModalType}
            />
          );
        })}
      </div>
    );
  };
  const renderSubscriptionsItems = () => {
    if (!subscriptionsListData?.length) {
      return (
        <EmptyResult
          text={t('noSubscriptionsMessage')}
          action={handleRefreshSubscriptions}
          loading={loading}
        />
      );
    }
    return sortedSubscriptionsListData.map(
      (subscriptionsListGroup: SubscriptionsListType[]) => {
        return subscriptionsListCard(subscriptionsListGroup);
      }
    );
  };

  return (
    <>
      <>
        <SubscriptionsElement>
          <RootPageTitle title={t('mySubscriptions')} />
          {!subscriptionsListData ? (
            <Loader />
          ) : (
            <>
              {renderSubscriptionsItems()}
              {loadMore && !!sortedSubscriptionsListData.length && (
                <LoadMoreButton
                  onClick={() => {
                    if (!loading) {
                      getSubscriptionsList(pageData + 1, subscriptionsList);
                    }
                  }}
                >
                  {loading ? <Loader small /> : t('loadMore')}
                </LoadMoreButton>
              )}
            </>
          )}
        </SubscriptionsElement>
      </>

      {modalType && (
        <Modal>
          <ModalBody isGray={modalType === 'paymentHistory'}>
            {modalType === 'info' || modalType === 'paymentHistory' ? (
              <IconClose
                onClick={() => {
                  setModalType('');
                }}
              />
            ) : null}
            {modalType === 'info' ? renderModalInfoBody() : null}
            {modalType === 'cancel' ? renderModalCancelBody() : null}
            {modalType === 'paymentHistory' ? (
              <SubscriptionPaymentsList
                subscriptionItem={activeSubscription as SubscriptionsListType}
              />
            ) : null}
          </ModalBody>
        </Modal>
      )}
    </>
  );
});
